import { Button, Card, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Radio, Select, Slider, Space, message } from "antd";
import { EcrfForm, EcrfSection, FieldTypeEnum, FormField, SaveFormDataVM, FormData } from "../models/models";
import { FormInstance, useForm, useWatch } from "antd/es/form/Form";
import { Rule } from "antd/es/form";
import { ReactNode, useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { saveFormValues } from "../models/serverApi";
import { CalculatorFilled } from "@ant-design/icons";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { bmiCalculation, egfrCalculation } from "../Calculations";

export const FormView: React.FC<{ section: EcrfSection | null | undefined, disabled: boolean, formValues: FormData | null | undefined, allFormValues?: FormData[] | null, formSaved: () => void }> = ({ section, disabled, formValues, allFormValues, formSaved }) => {
    const [context, setContext] = useContext(AppContext);
    const [antdForm] = useForm();

    useEffect(() => {
        if (!formValues) {
            antdForm.resetFields();
            antdForm.setFieldsValue(null);
            return;
        }

        let fv = formValues.formFieldValues?.reduce((pv, cv, i) => {
            let v = cv?.formValue.replace(/['"]+/g, '').trim();
            pv[`field-${cv.fieldId}`] = v;
            let ind = (cv.fieldId > 1000 ? cv.fieldId - 1000 : cv.fieldId);
            let field = section?.form?.formFields.find(ff => ff.id == ind);

            if (field && (field.fieldType == FieldTypeEnum.Double ||
                field.fieldType == FieldTypeEnum.Integer)) {
                pv[`field-${cv.fieldId}`] = Number(v);
            }

            return pv;
        }, {} as any);

        antdForm.setFieldsValue(fv);
    }, [section, formValues]);

    const formSubmit = () => {
        antdForm
            .validateFields()
            .then(values => {
                if (!section || !section?.form) {
                    return;
                }
                var formFieldValues = Object.entries(values)
                    .filter(([k, v]) => {
                        return k.startsWith("field-");
                    })
                    .map(([k, v]) => {
                        var key = parseInt(k.replace("field-", ""));

                        return {
                            fieldId: key,
                            formValue: JSON.stringify(v) ?? "",
                            isOtherValue: false as boolean
                        }
                    });

                var saveForm: SaveFormDataVM = {
                    formFieldValues: formFieldValues,
                    formId: section?.form.id,
                    sectionId: section?.id,
                    patientId: context.selection?.patient?.id ?? 0,
                    patientAdmissionId: context.selection?.admission?.id ?? 0
                }

                saveFormValues(saveForm).then((r) => {
                    message.success("Αποθηκεύτηκε");
                    formSaved();
                })
                    .catch(err => {
                        message.error("Σφάλμα κατα την αποθήκευση των δεδομένων" + err);
                    });

            })
            .catch(info => {
                console.log('Τα δεδομένα δεν είναι έγκυρα', info);
            });
    }

    return (<>
        {section?.form &&
            <Card title={`${section?.form.description}` + (section?.parentId != null ? ` - ${section.name}` : "")} extra={<Button type={"primary"} disabled={disabled} onClick={() => { formSubmit(); }}>Αποθηκευση</Button>}>
                <Form form={antdForm}
                    // labelCol={{ flex: '110px' }}
                    labelAlign="left"
                    labelWrap
                    wrapperCol={{ flex: 1 }}
                    colon={false}>
                    {section?.form.formFields.sort((a, b) => a.displayOrder - b.displayOrder)
                        .map(ff => <FormFieldRender key={ff.id} formField={ff} section={section} allFormValues={allFormValues}></FormFieldRender>)}
                </Form>
            </Card>}
    </>
    );
}

export const FormFieldRender: React.FC<{ formField: FormField, section: EcrfSection, allFormValues?: FormData[] | null }> = ({ formField, section, allFormValues }) => {
    const antdForm = useFormInstance();
    const [extraStyle, setExtraStyle] = useState<{ FollowUp: boolean, Conditional?: { fieldId: number, value: string } } | null>(null);
    const [visible, setVisible] = useState<boolean>(true);
    const field60 = useWatch('field-60', antdForm);
    const field63 = useWatch('field-63', antdForm);
    const field96 = useWatch('field-96', antdForm);

    useEffect(() => {
        if (!formField?.extraStyle) {
            return;
        }

        try {
            let xt = JSON.parse(formField.extraStyle);
            let hasFollowUp = xt.FollowUp == true;
            let conditional = xt?.Conditional;
            if (conditional) {
                setVisible(false);
            }
            setExtraStyle({ FollowUp: hasFollowUp, Conditional: conditional as any });

        }
        catch {
            console.log("exception", formField.extraStyle);
            return;
        }

    }, [formField]);

    useEffect(() => {
        if (extraStyle?.Conditional && extraStyle?.Conditional.fieldId == 60) {
            if (field60?.trim() != extraStyle?.Conditional?.value?.trim()) {
                setVisible(false);
                return;
            }
            setVisible(true);
        }

        if (extraStyle?.Conditional && extraStyle?.Conditional.fieldId == 63) {
            if (field63?.trim() != extraStyle?.Conditional?.value?.trim()) {
                setVisible(false);
                return;
            }
            setVisible(true);
        }
    }, [field60, field63]);

    useEffect(() => {
        if (field96?.length > 0) {
            antdForm.setFieldValue('field-55', null);
            antdForm.validateFields(["field-55"]);
        }
    }, [field96]);

    const buttonCalculateClick = (field: any) => {
        if (field.label == "ΒΜΙ") {
            let bmi = bmiCalculation(section, antdForm);
            if (bmi == null) {
                return;
            }
            antdForm.setFieldValue(`field-${field.id}`, parseFloat(bmi.toFixed(2)));
        }
        else if (field.label == "e-GFR") {
            let egfr = egfrCalculation(section, antdForm, allFormValues ?? null);
            if (egfr == null) {
                return;
            }

            antdForm.setFieldValue(`field-${field.id}`, parseFloat(egfr.toFixed(2)));
        }
    }

    const getFieldComponent = (hasUnits: boolean = true, placeholder: string | undefined = undefined) => {
        switch (formField.fieldType) {
            case FieldTypeEnum.Text:
                return <Input addonAfter={hasUnits ? formField.units : null} placeholder={placeholder} />;
            case FieldTypeEnum.CheckBox:
                return (<Checkbox.Group>
                    {formField.listValues &&
                        formField.listValues.split(',').map((option) => (
                            <Checkbox key={`checkbox-${option}`} value={option}>{option}</Checkbox>
                        ))}
                </Checkbox.Group>);
            case FieldTypeEnum.RadioButton:
                return (
                    <Radio.Group>
                        {formField.listValues &&
                            formField.listValues.split(',').map((option, i) => (
                                <Radio key={`radio-${option}`} value={option.trim()}>{option.trim()}</Radio>
                            ))}
                    </Radio.Group>
                );
            case FieldTypeEnum.ListBox:
                return (
                    <Select>
                        {formField.listValues &&
                            formField.listValues.split(',').map((option) => (
                                <Select.Option key={option} value={option}>
                                    {option}
                                </Select.Option>
                            ))}
                    </Select>
                );
            case FieldTypeEnum.Integer:
                return <InputNumber addonAfter={hasUnits ? formField.units : undefined} placeholder={placeholder} type="number" />;
            case FieldTypeEnum.Double:
                return <InputNumber
                    disabled={formField.extraStyle?.includes("Calculated")}
                    placeholder={placeholder}
                    addonAfter={hasUnits ? <>
                        <span>{formField.units}</span>
                        {formField.extraStyle?.includes("Calculated") && <Button onClick={() => { buttonCalculateClick(formField); }}><CalculatorFilled title={"Αυτόματος υπολογισμός"}></CalculatorFilled>Αυτόματος υπολογισμός</Button>}</> : undefined}
                    type="number" step={formField.interval ?? 0.1} />;
            case FieldTypeEnum.DateTime:
                return <DatePicker showTime />;
            case FieldTypeEnum.Percentage:
                if (formField.interval) {
                    let marks = {} as Record<string | number, ReactNode>;
                    for (let i = formField.min ?? 0; i <= (formField.max ?? 100); i += formField.interval ?? 1) {
                        marks[i.toString()] = i;
                    }

                    return <Slider
                        marks={marks}
                    />
                }

                return <Input addonAfter={hasUnits ? "%" : undefined} />;
            case FieldTypeEnum.Seperator:
                return <Divider />
            case FieldTypeEnum.Label:
                return <><p style={{ fontWeight: 600 }}>{formField.label}</p><Divider /></>
            default:
                return <></>;
        }
    }

    const getRules = (showMessage: boolean = true) => {
        let rules = [] as Rule[];
        if (formField.required && formField.fieldType != FieldTypeEnum.Label) {
            rules.push({ required: formField.required, message: !showMessage ? "" : `Το πεδίο "${formField.label}" είναι υποχρεωτικό ` });
        }

        if (formField.max != null) {
            rules.push({ type: 'number', max: formField.max, message: !showMessage ? "" : `Εισάγετε μια τιμη μικρότερη απο ${formField.max}` });
        }

        if (formField.min != null) {
            rules.push({ type: 'number', min: formField.min, message: !showMessage ? "" : `Εισάγετε μια τιμη μεγαλύτερη απο ${formField.min}` });
        }

        if (formField.minAlert) {
            rules.push({ type: 'number', validateTrigger: "onChange", min: formField.minAlert, warningOnly: true, message: !showMessage ? "" : `Πιθανή ακραία τιμή` });
        }

        if (formField.maxAlert) {
            rules.push({ type: 'number', validateTrigger: "onChange", max: formField.maxAlert, warningOnly: true, message: !showMessage ? "" : `Πιθανή ακραία τιμή` });
        }

        if (formField.id == 23) {
            rules.push(
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value && !getFieldValue('field-95')) {
                            return Promise.reject("Αν το πεδίο ABI δεν συμπληρωθεί, πρέπει να επιλέξετε ότι δεν μετρήθηκε");
                        }

                        return Promise.resolve();
                    },
                }));
        }

        if (formField.id == 55) {
            rules.push(
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        var chValue = getFieldValue('field-96');
                        var hasValue = value != null;
                        if (hasValue && chValue?.length > 0) {
                            return Promise.reject("Αν το πεδίο hCRP συμπληρωθεί, πρέπει να ΜΗΝ επιλέξετε ότι δεν μετρήθηκε");
                        }

                        if (!value && !getFieldValue('field-96')) {
                            return Promise.reject("Αν το πεδίο hCRP δεν συμπληρωθεί, πρέπει να επιλέξετε ότι δεν μετρήθηκε");
                        }

                        return Promise.resolve();
                    },
                }));
        }

        return rules;
    }

    const getLabel = () => {
        if (formField.fieldType == FieldTypeEnum.Label || formField.fieldType == FieldTypeEnum.Seperator) {
            return "";
        }

        return formField.label;
    }

    const getTooltip = () => {
        if (!formField.tooltip || formField.fieldType == FieldTypeEnum.Label || formField.fieldType == FieldTypeEnum.Seperator) {
            return null;
        }

        return formField.tooltip;
    }
    const getValueFromEvent = (values: any) => {
        if (formField.fieldType != FieldTypeEnum.CheckBox) {
            return values;
        }

        return values.map((id: number) => ({ id }));
    }
    const getValueProps = (ids: any) => {
        if (formField.fieldType != FieldTypeEnum.CheckBox) {
            return ids;
        }
    }

    return (<>
        {extraStyle?.FollowUp &&
            <Space.Compact style={{ width: "100%" }}>
                <Form.Item
                    colon={false}
                    key={formField.id}
                    label={getLabel()}
                    name={`field-${formField.id}`}
                    rules={getRules(false)}
                    tooltip={getTooltip()}
                    hasFeedback
                >
                    {getFieldComponent(false, "Baseline")}
                </Form.Item>
                <Form.Item
                    colon={false}
                    key={formField.id + "_1"}
                    name={`field-${formField.id + 1000}`}
                    rules={getRules()}
                    tooltip={getTooltip()}
                    hasFeedback
                >
                    {getFieldComponent(true, "3μηνη παρακολούθηση")}
                </Form.Item>
                {/* <Form.Item
                    style={{ width: "20%" }}>
                    <InputNumber disabled addonAfter={"%"} type="number" />
                </Form.Item> */}
            </Space.Compact>
        }
        {!!!extraStyle?.FollowUp && visible && <Form.Item
            colon={false}
            key={formField.id}
            label={getLabel()}
            name={`field-${formField.id}`}
            rules={getRules()}
            tooltip={getTooltip()}
            hasFeedback
        >
            {getFieldComponent(true)}
        </Form.Item>}
    </>
    );
}